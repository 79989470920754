table-div {
    margin-top: 10px;
    width: 100%;
  }

  .title {
    font-size: 18px;
    color: #000;
    padding: 10px 0px;
    font-family: "Plain-Medium";
  }

  .table-actions-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .searchbar-container {
    position: relative;
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
  }

  .searchbar-div {
    display: flex;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    color: #828282;
    padding: 8px 16px 8px 12px;
    width: 100%;
  }

  .searchbar-input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
  }

  .searchbar-search-icon-div {
    margin-left: 10px;
  }

  .custom-filter-button {
    border-radius: 8px;
    display: flex;
    gap: 12px;
    cursor: pointer;
    height: 40px;
    margin-right: 50px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 8px 16px 8px 12px;
    color: #424242;
    background-color: white;
    border: 1px solid #e0e0e0;
  }
  
  .custom-filter-container {
    position: relative;
  }
  
  .custom-filter-options {
    top: auto;
    position: fixed;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    z-index: 1001;
    margin-top: 5px;
    right: auto;
    padding: 16px;
    font-size: 14px;
    overflow: visible;
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
  }
  
  .custom-filter-columns {
    display: flex;
    gap: 32px;
  }
  
  .custom-filter-column {
    display: flex;
    flex-direction: column;
    min-width: 100px;
    width: fit-content;
  }
  
  .custom-filter-column-label {
    font-weight: bold;
  }
  
  .custom-filter-columns-button {
    color: var(--primary-color);
    font-size: 14px;
    border: none;
    background-color: transparent;
    align-self: flex-end;
    height: 36px;
    padding: 8px 12px;
  }
  .date-range-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 538px;
    cursor: pointer;
  }

  .date-range-container.show #calendar {
    display: block;
  }

  .date-range-container #calendar {
    width: 50px;
    position: absolute;
    left: 0;
    z-index: 2;
    display: none;
    pointer-events: none;
  }

  .table-container {
    width: 100%;
    height: calc(100% - 25px);
    border-radius: 5px;
    background-color: white;
  }

  .table-container.overflow {
    overflow-x: auto;
  }

  .titles {
    display: flex;
    height: 40px;
    background-color: white;
  }

  .title-columns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 auto;
  }

  .text-label-row-title {
    padding: 12px 12px 12px 0;
    display: inline-block;
    font-weight: bold;
    color: #828282;
    font-size: 16px;
    padding-left: 20px;
  }

  .div-icons-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #cfcece;
    margin-right: 15px;
    cursor: pointer;
  }

  .button-table {
    background-color: transparent;
    border: none;
    outline: none;
    color: black;
    cursor: pointer;
  }

  .button-table:hover,
  .button-table:focus {
    outline: none;
    border: none;
  }

  .table-row-container {
    overflow: auto;
    height: calc(100% - 40px);
    width: fit-content;
    min-width: 100%;
    overflow-x: hidden;
    background-color: white;
  }

  .generic-row {
    display: flex;
    height: 50px;
    align-items: center;
    border-top: 1px solid #e0e1e2;
    justify-content: flex-start;
    background-color: white;
  }

  .generic-row:hover {
    background-color: #e9ffee;
  }

  .generic-column {
    display: flex;
    align-items: center;
    color: #000;
    flex: 1 0 auto;
  }

  .text-label-row {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 6px;
    padding-left: 20px;
  }

  .actions-column {
    width: 100px;
    position: relative;
    padding: 12px 12px 12px 0;
    display: inline-block;
    font-weight: bold;
    color: #828282;
    font-size: 16px;
    padding-left: 20px;
  }

  .actions-popup {
    left: -150px;
    width: 200px;
    display: flex;
    flex-direction: column;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    z-index: 2;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 10px;
  }

  .action-button {
    width: 100%;
    color: black;
    border: none;
    padding: 12px;
    cursor: pointer;
    display: flex;
    gap: 12px;
    background-color: transparent;
  }

  .action-button:hover {
    color: var(--primary-color);
  }

  .action-button.disabled {
    color: #000;
    cursor: not-allowed;
  }

  .action-button.disabled:hover {
    color: #000;
  }

  .progress-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .progress-bar-container {
    display: flex;
    width: 50%;
    height: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #e0e0e0;
    overflow: hidden;
  }

  .progress-bar {
    width: 0;
    height: 20px;
    background-color: var(--primary-color);
    border-radius: 8px;
    animation: progress-bar-animation 13s linear infinite;
  }

  @keyframes progress-bar-animation {
    0% { width: 0%; }
    100% { width: 100%; }
  }

  .pagination-div {
    padding-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }

  .pagination-text {
    color: black;
    margin-right: 6px;
    margin-left: 6px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  .pagination-text.disabled {
    color: #828282;
    cursor: not-allowed;
  }

  .pagination-icon {
    margin-right: 6px;
    margin-left: 6px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: transparent;
    color: black;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pagination-icon.selected {
    background-color: #005A24;
    color: white;
  }

  .dropdown-container {
    width: 130px;
    margin-left: 10px;
  }

  @media (max-width: 680px) {
    .pagination-div {
      flex-wrap: wrap-reverse;
      justify-content: center;
    }

    .table-container {
      height: calc(100% - 50px);
    }
  }