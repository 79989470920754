@import "../../assets/fonts/fontsInterface.css";

.main-content {
  text-align: left;
  background-color: #F4F4F4;
}

.home-container {
  height: 98vh;
  border-radius: 5px;
  text-align: left;
  overflow-y: auto;
  font-family: "Plain-Regular";
  padding-top: 0.5em;
  width: auto;
}
.container__profile, .containerContent {
  flex: 1 1!important;
  background-color: white!important;
  padding: 24px!important;
  border-radius: 16px!important;
}
.home-welcome-container-user {
  position: relative;
  height: 64px;
  width: 66px;
  background-color: #117170;
  border-radius: 50%;
  border: 6px solid white;
  overflow: hidden;
  margin: 2px 0px;
  margin-top: 6x;
}
.container__homeWelcome{
  position: relative;
  height: 170px;
  width: 170px;
  background-color: #117170;
  border-radius: 50%;
  border: 6px solid white;
  overflow: hidden;
  margin: 2px 0px;
  box-shadow: 0px 0px 5px -1px black;
  margin-top: 6x;
}
.profileContainer {
  height: 16px;
  width: 16px;
  background-color: #117170;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  & img {
    width: 100%;
    height: auto;
  }
}
.container__sectionHome {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.container__alerts {
  width: 22.9%;
  border-radius: 0.3rem;
  border-radius: 2px;
  margin-left: 24px;
}
.home-wrapper {
  height: 100%;
}
.container__copyrightHome-home {
  width: 100%;
  height: fit-content;
  /* background-color: white; */
  padding-bottom: 32px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.3rem;
  border-radius: 2px;
  margin-bottom: 14px 0px;
  display: flex;
  justify-content: center;
  padding-bottom: 36px;
  margin-bottom: 14px;
  margin-top: 14px;
}
.container__infoHome {
  display: flex;
  padding-right: 12px 
}
.header__infoProfile {
  width: auto;
  margin-left: 17px;
  display: flex;
  flex-flow: column;
  gap: 16px;
}
.title__headerHome {
  font-weight: 600;
}
.container__infoHomeProfile{
  margin-left: 15px;
}
.tooltip-container {
  position: relative;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 8px;
  color: #585656;
  width: auto;
  background: #E5F2E9;
  display: flex;
  align-items: center;
}
.tooltip__home {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  transform-origin: bottom;
  padding: 0.3em 0.6em;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  border-radius: 4px;
  box-shadow: 0 2px 0px rgba(0, 0, 0, 0.1);
}
.tooltip-container:hover .tooltip__home {
  top: 35px;
  opacity: 100;
  visibility: visible;
  pointer-events: auto;
  transform: translateX(-50%) scale(1);
  font-weight: 500;
  width: 161px;
  background-color: #343434;
  color: #ffff;
  z-index: 9;
  padding: 8px 16px;
  border-radius: 8px;
}
.container__tooltipInfo {
  margin-top: 7px;
  display: flex;
  column-gap: 14px;
  flex-wrap: wrap;
  row-gap: 11px;
}
.container__btnPremiumRequestButton {
  background-color: rgb(6, 172, 56);
  border-radius: 5px;
  margin-top: 22px;
  padding: 7px;
  color: #fff;
  padding-bottom: 13px;
  border: none;
  width: 244px;
  border: none;
  outline: none;
  text-align: center;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
}
.container__btnPremiumRequest {
  background-color: rgb(6, 172, 56);
  border-radius: 5px;
  margin-top: 22px;
  padding: 7px;
  color: #fff;
  padding-bottom: 13px;
  border: none;
  width: 244px;
  border: none;
  outline: none;
  text-align: center;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
}
select, .container__btnPremiumRequest {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 5px;
}
.icon__caretDown {
  margin-left: 10px;
}
.icon__infoHeader {
  margin-right: 10px;
  margin-left: 3px;
}
.buttonsContainer__btnPremiumReques{
  display: grid;
  font-size: 13px;
}
.container__btsPremiun {
  position: absolute;
  left: 56%;
  top: 26%;
}
.container__premiumRequestBtn {
  margin-left: auto;
}
.buttonsContiner__modal {
  position: absolute;
  display: grid;
  left: 54%;
  top: 143px;
}
.button__request {
  padding: 7px;
  color: #fff;
  padding-bottom: 13px;
  border: none;
  background-color: none;
  background: #fff;
  color: rgb(6, 172, 56);
  box-shadow: 0px 13px 34px rgba(0, 0, 0, 0.3);
  margin-bottom: 3px;
}
.card-dashboard-fit-sortingtable {
  height: fit-content;
  background-color: white;
  padding-left: 23px;
  padding-right: 12px;
  padding-bottom: 20px;
  padding-top: 10px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
  /* border-left: 3px solid rgb(6, 172, 56);
  border-right: 3px solid rgb(6, 172, 56); */
}
.button__info {
  background-color: transparent;
  border: 1px solid rgb(6, 172, 56);
  border-radius: 5px;
  background-color: rgb(6, 172, 56);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
}
.container__startEnd .home-welcome-container-user {
  border: 6px solid rgb(216 191 50);
  box-shadow: 0px 0px 7px #00000038;
 }
 .container__startEndSilver .home-welcome-container-user {
  border: 6px solid rgba(192, 192, 192, 1);
  box-shadow: 0px 0px 7px #00000038;
 }

 @keyframes animateBorderSilver {
  from {
    border-image: linear-gradient(to bottom, transparent 50%, black 50%, black 95%, transparent 100%) 1 stretch;
  }
  to {
     border-image: linear-gradient(to bottom, transparent 0%, black 0%, black 50%, transparent 50%) 1 stretch;
  }
}
 .container__premiumServiceRequestCard {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
 }

 .container__cardPremiumServiceRequest {
  display: flex;
  flex-direction: column;
  color: #343434;
  justify-content: space-around;
  width: 200px;
  padding: 16px;
  margin-bottom: 5px;
  border-radius: 16px;
  background-color: #FAFAFA;
  border-left: 2px solid #117170;
  flex: 1 0 24%;
 }
 .subtitle__premiumServiceRequestCard {
  color: #7b7b7b;
  font-size: 15px;
  margin-top: 10px;
 }

 .gold:before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: black;
  opacity: 0.5;
  z-index: -1;
}
.gold {
  margin-top: 5px;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(186, 148, 62, 1) 0%,
    rgba(236, 172, 32, 1) 20%,
    rgba(186, 148, 62, 1) 39%,
    rgb(255 255 255) 50%,
    rgba(186, 148, 62, 1) 60%,
    rgba(236, 172, 32, 1) 80%,
    rgba(186, 148, 62, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite ease;
  background-size: 200%;
  background-position: right;
}
.silver:before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: black;
  opacity: 0.5;
  z-index: -1;
}
.silver {
  margin-top: 5px;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(192, 192, 192, 1) 0%,  
    rgba(160, 160, 160, 1) 20%,  
    rgba(192, 192, 192, 1) 39%, 
    rgb(224, 224, 224) 50%,      
    rgba(192, 192, 192, 1) 60%, 
    rgba(160, 160, 160, 1) 80%,  
    rgba(192, 192, 192, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite ease;
  background-size: 200%;
  background-position: right;
}
.percentage:before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: black;
  opacity: 0.5;
  z-index: -1;
}
.percentage {
  margin-top: 5px;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  background: linear-gradient(
    135deg,
    rgba(56, 212, 116, 1) 0%,  /* Lighter shade */
    rgba(6, 172, 56, 1) 50%,   /* Original color */
    rgba(4, 122, 40, 1) 100%   /* Darker shade */
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite ease;
  background-size: 200%;
  background-position: right;
}
.container__all {
  width: 75%;
}
.container__productSupportSummar {
  display: flex;
  gap: 24px;
}
.container__productHome {
  height: fit-content;
  background-color: white;
  padding-bottom: 20px;
  padding-top: 10px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 49%;
  margin-top: 15px;
  padding-left: 23px;
  padding-right: 12px;
}
.container__supportSummaryHome {
  height: fit-content;
  background-color: white;
  padding-bottom: 20px;
  padding-top: 10px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 49%;
  margin-top: 15px;
  padding-left: 23px;
  padding-right: 12px;
}
.title__card {
  font-size: 16px;
  display: flex;
  flex-direction: column;
}
.container__alerts h2 {
  line-height: 32px;
  font-weight: 700;
  opacity: 0.9;
  margin: 0;
  color: var(--main-color);
  font-size: 18px;
}
.news-item__home {
  padding: 24px;
  margin-bottom: 5px;
  border-radius: 16px;
  background-color: #FAFAFA;
}
.btn-secondary {
  align-items: center;
  background-color: transparent;
  border: 1px solid #535353;
  color: #535353;
  display: flex;
  border-radius: 8px;
}
.btn-secondary:hover {
  background-color: #E9E9E9;
  border: 1px solid #E9E9E9;
  color: #535353;
}
.btn-primary, .btn-secondary {
  gap: 8px;
}
.btn-secondary svg, .btn-primary svg{
  height: 20px;
  width: auto;
}
.title__home {
  margin-top: 3px;
  margin-bottom: 8px;
  font-size: 14px;
}
.description__newHome {
  margin-bottom: 10px;
  color: #8d8888;
  font-size: 12px;
  margin-bottom: 15px;
}
.meta__home {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em; 
  margin-bottom: 10px;
  font-size: 12px;
}
.urgency__home {
  background: #c12f2a;
  padding: 0.5em;
  border-radius: 4px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.date__home {
  padding: 0.5em;
  font-weight: bold;
  color: #888; 
}
.view-all {
  color: #fff;
  background: #44a03d;
  border: 1px solid #44a03d;
  border-radius: 5px;
  display: block;
  width: auto;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  height: 34px;
  font-size: 13px;
}
/* .view-all:hover {
  border-radius: 5px;
  color: white;
  background: #44a03d;
} */
.title__myProducts {
  margin-bottom: 15px;
  color: var(--main-color);
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  opacity: .9;
}
.tablet__premiumSupportSummary {
  margin-top: 10px;
}
.card-dashboard-fit {
  width: 100%;
  height: fit-content;
  background-color: white;
  padding: 20px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.3rem;
  border-radius: 2px;
  margin: 20px 0px;
  display: flex;
  justify-content: center;
}
.container__admin {
  background-color: #FFE2B1;
  padding: 16px;
  border: 1px solid #E87000;
  border-radius: 8px;
  margin-bottom: 6px;
  color: #343434;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}
.containerMessage {
  column-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container__cardPremiumServiceRequest:not(:last-child) {
  border-right: none;
}
.date__current {
  color: rgb(6, 172, 56);
}
.tablet__home {
  height: 80px;
  width: 200px;
  text-align: center;
  padding: 10px;
  margin-bottom: 5px;
  box-shadow: 14px -7px 57px -20px rgba(0,0,0,0.1), 0px 10px 15px -3px rgba(0,0,0,0.1);
  border-radius: 3px;
  background-color: white;
  border: none;
}
.animated-border {
  border-left: 4px solid;
  border-image: linear-gradient(
  to top,
  rgba(165, 133, 55, 1) 0%,
  rgba(212, 157, 28, 1) 20%,
  rgba(165, 133, 55, 1) 40%,
  rgba(220, 220, 220, 1) 50%,
  rgba(165, 133, 55, 1) 60%,
  rgba(212, 157, 28, 1) 80%,
  rgba(165, 133, 55, 1) 100%
  ) 1;
  animation: animateBorder 5s infinite ease;
} 
@keyframes animateBorder {
  20% {
  border-image: linear-gradient(
  to top,
  rgba(165, 133, 55, 1) 0%,
  rgba(212, 157, 28, 1) 20%,
  rgba(165, 133, 55, 1) 40%,
  rgba(220, 220, 220, 1) 50%,
  rgba(165, 133, 55, 1) 60%,
  rgba(212, 157, 28, 1) 80%,
  rgba(165, 133, 55, 1) 100%
) 1;
  }
  39% {
  border-image: linear-gradient(
  to right,
  rgba(165, 133, 55, 1) 0%,
  rgba(212, 157, 28, 1) 20%,
  rgba(165, 133, 55, 1) 40%,
  rgba(220, 220, 220, 1) 50%,
  rgba(165, 133, 55, 1) 60%,
  rgba(212, 157, 28, 1) 80%,
  rgba(165, 133, 55, 1) 100%
) 1;
  }
  50% {
  border-image: linear-gradient(
  to bottom,
  rgba(165, 133, 55, 1) 0%,
  rgba(212, 157, 28, 1) 20%,
  rgba(165, 133, 55, 1) 40%,
  rgba(220, 220, 220, 1) 50%,
  rgba(165, 133, 55, 1) 60%,
  rgba(212, 157, 28, 1) 80%,
  rgba(165, 133, 55, 1) 100%
) 1;
  }
  60% {
  border-image: linear-gradient(
  to top,
  rgba(165, 133, 55, 1) 0%,
  rgba(212, 157, 28, 1) 20%,
  rgba(165, 133, 55, 1) 40%,
  rgba(220, 220, 220, 1) 50%,
  rgba(165, 133, 55, 1) 60%,
  rgba(212, 157, 28, 1) 80%,
  rgba(165, 133, 55, 1) 100%
) 1;
  }
  80% {
  border-image: linear-gradient(
  to right,
  rgba(165, 133, 55, 1) 0%,
  rgba(212, 157, 28, 1) 20%,
  rgba(165, 133, 55, 1) 40%,
  rgba(220, 220, 220, 1) 50%,
  rgba(165, 133, 55, 1) 60%,
  rgba(212, 157, 28, 1) 80%,
  rgba(165, 133, 55, 1) 100%
) 1;
  }
  100% {
  border-image: linear-gradient(
  to bottom,
  rgba(165, 133, 55, 1) 0%,
  rgba(212, 157, 28, 1) 20%,
  rgba(165, 133, 55, 1) 40%,
  rgba(220, 220, 220, 1) 50%,
  rgba(165, 133, 55, 1) 60%,
  rgba(212, 157, 28, 1) 80%,
  rgba(165, 133, 55, 1) 100%
  ) 1;
  }
}
.btn__disabled {
  opacity: 0.5;
}
.container__currentDate {
  display: flex;
  margin: 12px 0px;
}
.containeAlertPills {
  display: flex;
  flex-flow: column;
  gap: 16px;
}

@media screen and (max-width: 520px) {
  .container__alerts {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
}
















.home-title-text {
  display: flex;
  column-gap: 10px;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #117170;
  margin-left: 10px;
}

.menu-container-top {
  width: 100%;
  display: flex;
  column-gap: 15px;
}

.card-dashboard-container-side-left {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.card-dashboard {
  width: 400px;
  height: 100%;
  background-color: white;
  padding: 20px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.3rem;
  border-radius: 2px;
}



.home-welcome-cards-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 5px;
  width: 100%;
}

.container__universityAndMyProducts{
  display: flex;
  column-gap: 15px;
  width: 100%;
  margin-top: 6px;
}

.home-title-span {
  font-size: 20px;
  background-color: #117170;
  padding: 10px 20px;
  border-radius: 20px;
  color: white;
}

.home-title-span-user {
  font-size: 22px;
  background-color: #002b4f;
  padding: 10px 20px;
  border-radius: 3px;
  color: white;
  margin-top: 10px;
}

.MenuRegularIcon {
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 20px;
}

.center-corner {
  background-image: radial-gradient(#ffffff33 40%, #06ac38 60%);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0% 0%;
  transition: background-size 0.5s, color 0.5s;
}

.center-corner:active {
  background-size: 100% 100%;
  color: #fff;
}

.home-pagey {
  width: 2em;
}

.page-content {
  background-color: #fff;
}

.logout_button {
  background-color: white;
  color: gray;
  top: 53px;
  position: relative;
}

.menu-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
}

.menu-options {
  margin: 0px 20px;
  width: calc(33.33% - 40px);
  background-color: white;
  border: 1px solid #e7eaed;
  border-radius: 0.3rem;
  height: fit-content;
  box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
  display: flex;
  flex-direction: column;
  height: 300px;
  margin-bottom: 30px;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.iconAlertExit {
  height: 24px;
  width: 24px;
}
.home-welcome-container {
  position: relative;
  background-position: center;
  height: 94px;
  width: 100%;
  background-size: cover;
  border-radius: 2px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.3rem;
  align-items: center;
  border: 1px solid white;
  margin-bottom: 9px;
}
.home-welcome-container-text {
  width: 100%;
  height: 165px;
  background-color: white;
  border-top: 3px solid white;
  position: relative;
}
.home-welcome-container-user-img {
  height: 100%;
  right: -1px;
  position: absolute;
}
.img__homeWelcome {
  height: 170px;
  right: -5px;
  position: absolute;
}
.home-welcome-container-user-cards {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  width: 100%;
  justify-content: center;
}

.home-welcome-container-user-card-1 {
  height: fit-content;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.3rem;
  border-radius: 2px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  width: 100%;
}

.home-welcome-container-link {
  width: 100%;
  display: flex;
  justify-content: center;
  color: blue;
  text-decoration-line: underline;
  cursor: pointer;
}

.home-welcome-container-user-card-2 {
  height: fit-content;
  background-color: #00961045;
  border: 3px solid #009610;
  padding: 5px 0px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.25rem;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #009610;
  width: 100%;
}

.home-welcome-container-user-card-3 {
  height: fit-content;
  background-color: #86df1445;
  border: 3px solid #86df14;
  padding: 5px 0px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.25rem;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #86df14;
  width: 100%;
}

.app__title {
  color: #fff;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  background-color: #ffffff5e;
  padding: 10px 20px;
  width: fit-content;
  border-radius: 7px;
  border: 1px solid white;
}
.container__summaryInfo > .eyJXde {
  padding: 10px;
  display: flex;
  height: auto;
}
.container__textSlider {
  width: 100%;
}

@media (min-width: 960px) {
  .menu-options .img-container {
    width: 100%;
    height: 231px;
    position: relative;
  }

  .menu-container {
    max-width: 960px;
  }
}

@media (max-width: 960px) {
  .menu-options {
    width: 100%;
    height: fit-content;
  }

  .menu-container {
    max-width: 100%;
  }

  .menu-link-intro {
    position: relative;
  }
}

@media (min-width: 1280px) {
  .menu-container {
    max-width: 1280px;
  }
}

.menu-name {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  padding: 5px;
  border-top-left-radius: 3px;
}

.menu-name svg {
  width: 24px;
  margin-left: 20px;
  height: 50%;
}

.titlebar {
  height: 30px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: end;
}

/* .titlebar button {
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
} */

.health h3 {
  color: #1fad4b;
}

/* .home-wrapper {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 48px;
} */

.side-panel {
  background-color: #e7ddcf;
  max-width: 345px;
  width: 100%;
  max-height: 753px;
  border-radius: 5px;
  height: 100%;
  padding-top: 20px;
}

.navigation-container {
  text-align: left;
}

.navigation-container h2 {
  margin-bottom: 2px;
}

.navigation-divider {
  border: 2px solid black;
}

.user-name {
  font-size: 24px;
  font-weight: lighter;
}

.user-image {
  width: 228px;
  width: 222px;
  border-radius: 50%;
}

.user-title {
  font-size: 16px;
  font-weight: bold;
}

.nav-link {
  color: #035f7f;
  text-decoration: none;
  margin: 15px 0;
  cursor: pointer;
}

.menu-link-intro {
  text-decoration: none;
  color: #005a24;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
  margin: 20px;
}

.menu-link-intro label {
  margin-top: 10px;
}

body {
  background-color: #edf1f3;
}

.ribbon {
  position: absolute;
  left: -5px;
  top: 53px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 17px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #0044ff;
  background: #0044ff;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  left: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #0044ff;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #0044ff;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #0044ff;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #0044ff;
}
.beta {
  text-align: center;
}
.beta h1 {
  margin-left: 10px;
  color: #fff;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Josefin Sans", sans-serif;
  background: linear-gradient(to right, #095fab 10%, #25abe8 50%, #57d75b 60%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1.5s linear infinite;
  display: inline-block;
}
.submit__button {
  position: relative;
  display: inline-block;
  padding: 5px 7px;
  text-align: center;
  font-size: 17px;
  text-decoration: none;
  color: #002b4f;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 1px solid #002b4f;
  border-radius: 5px;
  box-shadow: inset 0 0 0 0 #002b4f;
  left: 87%;
}
.submit__button:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #002b4f;
}
.submit__button:active {
  transform: scale(0.9);
}
.disabled__dtnSave {
  opacity: 0.5;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.FAQ {
  display: flex;
  justify-content: end;
  align-items: center;
}

.FAQIcon {
  cursor: pointer;
  color: #06ac38;
  font-size: 16px;
  margin-left: 10px;
}

.rowContainerPDU {
  display: flex;
  column-gap: 20px;
  margin: 20px 0px;
  justify-content: space-between;
  align-items: center;
}
.PDUniversityDiv {
  display: flex;
  width: 100%;
  padding: 15px;
  color: rgb(37, 37, 37);
  background-color: white;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.25rem;
}
.universityAndMyProducts__university, .universityAndMyProducts__products {
  background-color: #fff;
  border-radius: 0.3rem;
  width: 50%;
  height: 230px;
}

.projects-section {
  background-color: #fff;
  border-radius: 0.3rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-left: 9px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
  width: 50%;
  height: 230px;

  &-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    color: var(--main-color);

    p {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      opacity: 0.9;
      margin: 0;
      color: var(--main-color);
    }

    .time {
      font-size: 20px;
    }
  }
}

.projects-section-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.projects-status {
  display: flex;
  justify-content: space-between;
}

.item-status {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  text-align: center;

  &:not(:last-child) .status-type:after {
    content: "";
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translatey(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 1px solid var(--secondary-color);
  }
}

.status-number {
  color: rgb(152, 166, 173);
  font-size: 14px;
}

.status-type {
  font-size: 14px;
  font-weight: bold;
}

.projects-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  color: var(--main-color);
  line-height: 32px;
  font-weight: 700;
  opacity: 0.9;
  margin: 0;
  color: var(--main-color);
  font-size: 18px;
}
.university__header {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
  margin-bottom: 0px;
}
.slider__app {
  width: 100%;
  background-color: white;
  padding: 20px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.app__slider {
  height: 80%;
  width: 100%;
}
.app__total {
  text-align: center;
  color: #ffff;
  font-size: 20px;
}
.container__role {
  display: flex;
  margin-top: 15px;
}
.role__title {
  font-weight: 600;
}
.role__type {
  margin-left: 10px;
  font-size: 20px;
  color: #005a24;
}
.container__university {
  width: 100%;
  background-color: #fff;
  border-radius: 0.3rem;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
  margin-top: 6px;
}
.container__sliderAndInfo {
  width: 100%;
}
.title__modal {
  font-size: 15px;
  color: #002b4f;
  font-weight: 600;
}
.container__fields {
  margin-bottom: 15px;
}
.text__typeRequest {
  border: 1px solid #002b4f;
  border-radius: 5px;
  padding: 6px;
  opacity: 0.5;
  margin-top: 7px;
}
.textarea__requestDetail {
  margin-left: 0px;
  border: 1px solid #002b4f;
  border-radius: 5px;
  margin-top: 7px;
  height: 100px;
  width: 100%;
}
.select__requestUrgency {
  border: 1px solid #002b4f;
  border-radius: 5px;
  margin-top: 7px;
  width: 100%;
  padding: 6px;
}



@media screen and (max-width: 980px) {
  .project-boxes.jsGridView .project-box-wrapper {
    width: 50%;
  }

  .status-number,
  .status-type {
    font-size: 14px;
  }

  .status-type:after {
    width: 4px;
    height: 4px;
  }
}

@media screen and (max-width: 720px) {
  .app-name,
  .profile-btn span {
    display: none;
  }

  .add-btn,
  .notification-btn,
  .mode-switch {
    width: 20px;
    height: 20px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .app-header-right button {
    margin-left: 4px;
  }
}

@media screen and (max-width: 520px) {
  .projects-section {
    overflow: auto;
  }
  .project-boxes {
    overflow-y: visible;
  }

  .app-sidebar,
  .app-icon {
    display: none;
  }

  .app-content {
    padding: 16px 12px 24px 12px;
  }

  .status-number,
  .status-type {
    font-size: 10px;
  }

  .app-header {
    padding: 16px 10px;
  }

  .search-input {
    max-width: 120px;
  }

  .project-boxes.jsGridView .project-box-wrapper {
    width: 100%;
  }

  .projects-section {
    padding: 24px 16px 0 16px;
  }

  .profile-btn img {
    width: 24px;
    height: 24px;
  }

  .app-header {
    padding: 10px;
  }

  .projects-section-header span,
  .projects-section-header .time {
    font-size: 18px;
  }

  .status-type {
    padding-right: 4px;

    &:after {
      display: none;
    }
  }

  .box-content-header {
    font-size: 12px;
    line-height: 16px;
  }

  .box-content-subheader {
    font-size: 12px;
    line-height: 16px;
  }

  .project-boxes.jsListView .project-box-header > span {
    font-size: 10px;
  }

  .box-progress-header {
    font-size: 12px;
  }

  .box-progress-percentage {
    font-size: 10px;
  }

  .days-left {
    font-size: 8px;
    padding: 6px 6px;
    text-align: center;
  }

  .project-boxes.jsListView .project-box > * {
    margin-right: 10px;
  }

  .project-boxes.jsListView .more-wrapper {
    right: 2px;
    top: 10px;
  }
}

.projects {
  background-color: #fff;
  width: 100%;
}
.projects-inner {
  border-radius: 4px;
}
.projects-header {
  color: black;
  text-align: start;
  margin-bottom: 17px;
}
.projects-header .count,
.projects-header .title {
  display: inline-block;
}
.projects-header .count {
  color: #738297;
}
.projects-header .zmdi {
  cursor: pointer;
  float: right;
  font-size: 16px;
  margin: 5px 0;
}
.projects-header .title {
  font-size: 21px;
}
.projects-header .title + .count {
  margin-left: 5px;
}
.projects-table {
  background: #fff;
  width: 100%;
  /* border: 1px solid ; */
  text-align: center;
}
.projects-table td,
.projects-table th {
  padding: 4px 10px;
  vertical-align: middle;
}
.projects-table td p {
  font-size: 12px;
}
.projects-table td p:last-of-type {
  color: gray;
  font-size: 13px;
}
.projects-table th {
  background-color: rgb(6, 172, 56);
  color: #fff;
  font-size: 14px;
}
.projects-table tr:hover {
  background-color: #fff;
}
.projects-table tr:not(:last-of-type) {
  border-bottom: 1px solid gray;
}
.projects-table .member figure,
.projects-table .member .member-info {
  display: inline-block;
  vertical-align: top;
}
.projects-table .member figure + .member-info {
  margin-left: 7px;
}
.projects-table .member img {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}
.projects-table .status > form {
  float: right;
}
.projects-table .status-text {
  display: inline-block;
  font-size: 12px;
  margin: 11px 0;
  padding-left: 20px;
  position: relative;
}
.projects-table .status-text:before {
  border: 3px solid;
  border-radius: 50%;
  content: "";
  height: 14px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 14px;
}
.projects-table .status-text.status-blue:before {
  border-color: #1c93ed;
}
.projects-table .status-text.status-green:before {
  border-color: #66b92e;
}
.projects-table .status-text.status-orange:before {
  border-color: #da932c;
}
.projects-table .status-text.status-red:before {
  border-color: #d65b4a;
}
.selectric {
  background-color: transparent;
  border-color: gray;
  border-radius: 4px;
}
.selectric .label {
  color: gray;
  line-height: 34px;
  margin-right: 10px;
  text-align: left;
}
.selectric-wrapper {
  float: right;
  width: 150px;
}

.title-section-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #313131;
}

.title-section-header p {
  font-size: 24px;
}


@keyframes shine {
  to {
    background-position: left;
  }
}

.buttonInfo {
  color: #117170 !important;
  font-size: 16px !important;
}
.buttonInfo:hover {
  cursor: pointer;
  color: #06ac38 !important;
}


.icon__dashboard{
  margin-top: 15px;
  display: flex;
}
.container__searchOngoingProjects {
  width: 100%;
  margin-bottom: 10px;
}
.searchOngoingProjects__input:focus {
  outline: none;
}
.searchOngoingProjects__input {
  border: 1px solid  #002b4f;
  margin-left: 0px;
  margin-right: 0px;
}
.input__containerOngoingProjects {
  position: relative;
}
.icon__containerSearchOngoingProjects {
  position: absolute;
  top: 50%;
  right: 10px; 
  transform: translateY(-50%);
  cursor: pointer;
  color: #002b4f
}
.not__customer {
  margin-top: 10px;
}
@media (width < 700px) {
  .home-welcome-cards-container {
    margin: 25px 0px;
  }
  .title__summary, .button__summary {
    padding-bottom: 30px;
  }
  .card-dashboard {
    width: auto;
  }
  .menu-container-top{
    flex-wrap: wrap;
  }
  .card-dashboard-fit-sortingtable {
    margin-top: 10px;
  }
  .projects-section {
    width: 100%;
  }
  .container__universityAndMyProducts{
    flex-wrap: wrap;
  }
  .universityAndMyProducts__products {
    width: auto;
    height: 247px;
    margin-top: 10px;
  }
}

.spinner {
  --clr: rgb(0, 113, 128);
  --gap: 6px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
 }
 
 .spinner span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--clr);
  opacity: 0;
 }
 
 .spinner span:nth-child(1) {
  animation: fade 1s ease-in-out infinite;
 }
 
 .spinner span:nth-child(2) {
  animation: fade 1s ease-in-out 0.33s infinite;
 }
 
 .spinner span:nth-child(3) {
  animation: fade 1s ease-in-out 0.66s infinite;
 }

 .home_text_1_no_alerts {
  color: rgb(6, 172, 56);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
 }

 .home_text_2_no_alerts {
  margin-bottom: 20px;
 }

 .home_text_3_no_alerts {
  color: gray;
 }

 .container__dataNotFound {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
 }
 .notfound__banners {
  text-align: center;
  margin-top: 30px;
 }
 .notfound__premiumServiceRequest {
  text-align: center;
  width: 100%;
}
.notfound__products {
  text-align: center;
  width: 100%;
  margin-top: 19px;
  padding: 1px;
}
.notfound__projects {
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
.button__try {
  border: 1px solid rgb(6, 172, 56);
  border-radius: 5px;
  background-color: rgb(6, 172, 56);
  color: #fff;
  font-size: 13px;
  margin-top: 15px;
  width: 90px;
  height: 36px;
}
.try__alerts{
  margin-top: 0px;
}
 @keyframes fade {
  0%, 100% {
   opacity: 1;
  }
 
  60% {
   opacity: 0;
  }
 }
 .container__error {
  text-align: center;
 }
 .container__thankYou {
  display: flex;
  justify-content: center;
 }
 .container__headerPremiumServiceRequest {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
 }
 .period__end {
  margin-left: 27px;
 }
 .container__searchProjects {
  height: 100%;
  border-radius: 5px;
  text-align: left;
  overflow-y: auto;
  font-family: "Plain-Regular";
}
.input__containerProjects {
  position: relative;
}
.searchProjects__input:focus {
  outline: none;
}
.searchProjects__input {
  border: 1px solid  #002b4f;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  padding: 9px;
  border-radius: 5px;
}
.icon__containerSearchProjects{
  position: absolute;
  top: 50%;
  right: 10px; 
  transform: translateY(-50%);
  cursor: pointer;
  color: #002b4f;
}
.container__totalProjects {
  display: flex;
  margin-top: 10px;
}
.pagination_container {
  margin-top: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.small-svg circle {
  stroke: #117170!important; 
}
.container__exit {
  border: 1px solid rgb(6, 172, 56);
  border-radius: 5px;
  background-color: rgb(6, 172, 56);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.icon__exit {
  margin-left: 10px;
}
.container__multiSelectSubdomain {
  display: flex;
  margin-bottom: 10px;
}
.multiSelect__subdomainHome {
  width: 100%;
}
.submit__multiSelect {
  width: 10%;
  text-align: end;
}
.dropdown__subdomainHome {
  display: block;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1;
  height: 200px;
  overflow-y: scroll;
  width: 70%;
}
.dropdown__subdomainHome li {
  padding: 8px 12px;
}
.search__subdomainHomeFilter {
  padding: 5px;
  margin: 10px;
  width: 97%;
}
@media screen and (min-width: 2560px) {
  .dropdown__subdomainHome {
    width: 72%;
  }
}

.icon__svg {
  width: 1.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
  margin-left: 10px;
 }
 
 .icon__circle {
  fill: none;
  stroke: hsl(0, 0%, 100%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
 }
 
 @keyframes rotate4 {
  100% {
   transform: rotate(360deg);
  }
 }
 
 @keyframes dash4 {
  0% {
   stroke-dasharray: 1, 200;
   stroke-dashoffset: 0;
  }
 
  50% {
   stroke-dasharray: 90, 200;
   stroke-dashoffset: -35px;
  }
 
  100% {
   stroke-dashoffset: -125px;
  }
 }

 .subdomain__circle {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
 }
 .icon__circleSubdomain {
  fill: none;
  stroke: rgb(6, 172, 56);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
 }

 .header__PremiumServiceRequest {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    opacity: 0.9;
    margin: 0;
    color: var(--main-color);
    font-size: 18px;
    display: flex;
    justify-content: space-between;
 }

 .link_premiumSummary{
    font-size: 13px;
    cursor: pointer;
    color: rgb(6, 172, 56);
 }
 
.container_premiumSummary {
  display: flex;
  column-gap: 1rem;
    /* display: grid; */
  flex-direction: column;
  margin-top: 1rem;
}

.item_premiumSummary {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.container_premiumSummary_items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item_premiumSummary_subtitle {
  margin-right: 10px;
}

.capsuleChartContainer {
  flex: auto;
}
.capsuleChartContainer .item_premiumSummary {
  border: 1px solid #E9E9E9;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 0.4rem;
}
.capsuleChartContainer .itemPremiumSummaryNumber {
  border-left: 1px solid #E9E9E9;
  padding-left: 8px;
}
.progressWrapper {
  position: relative;
  width: 250px;
}
.progressWrapper .progressColor {
    width: 250px;
    height: 12px;
    margin-bottom: 60px;
    appearance:none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: 2px;
    overflow: hidden;
    background-color: #d7d7d7;
}
.progressWrapper .progressColor::webkit-progress-bar {
  background-color: #d7d7d7;
  -moz-transition: 4s;
  -o-transition: 4s;
  -webkit-transition: 4s;
  transition: 4s;
}
.progressWrapper .tooltip2 {
  display: inline-block;
  background-color: #717880;
  font-size: 11px;
  color: #fff;
  padding: 4px 8px;
  width: auto;
  border-radius: 3px;
  position: absolute;
  top: -27px;
  left: 0%;
  margin-left: -15px;
}

.progressWrapper .tooltip2:after {
  content: '';
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid #717880;
  position: absolute;
  left: 50%;
  top: 100%;
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);  
}

.progressWrapper .tooltip2 {
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  left: 0%;
}

.progressWrapper progress.progressColor::-moz-progress-bar {
  background: #08da9d;
  border-radius: 2px;
  position: relative;
}

.progressWrapper progress.progressColor::-webkit-progress-value {
  background: #08da9d;
  border-radius: 2px;
  position: relative;
}
.progressColor progress[value]{
  color: #08da9d;
}
.progressColor progress[value]::-webkit-progress-bar {
  background-color: #d7d7d7;
}
.container__viewAllAlert {
  display: flex;
  justify-content: space-between;
}
.download__summary {
  width: max-content;
  float: inline-end;
}
.view__andDownload {
  margin-right: 20px;
}

.round__icon__modal {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.round__icon__modal img {
  height: 18px;
  width: 18px;
}
.popupModalHomeSmall {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 24px;
}

.popupModalHomeSmallText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  gap: 10px;
}

.popupModalHomeSmallButton {
  border-radius: 8px;
  padding: 8px 16px;
  border: none;
  outline: none;
  background-color: #048A24;
  font-size: 16px;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

.popupModalHomeSmallButton:hover {
  background-color: #005a24;
}

.popupModalHomeSecondSmallTextTitle{
  font-size: 16px;
  max-width: 385px;
}

.popupModalHomeSecondBoldSmallTextTitle {
  font-weight: 600;
}

.free-trial-viewer-blur-img {
  width: 100%;
  margin-top: 16px;
}
.card__service:hover .percentage:before  {
  background: none;
}
.hours__expireHome {
  margin-bottom: 15px;
}
.container__expirationDateHome {
  margin-top: -20px;
  margin-bottom: 24px;
}